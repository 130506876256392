.container {
    @apply mt-0 absolute z-10 flex items-center justify-center inset-x-0 bottom-0;
}
.containerInner {
    @apply md:pt-5 pt-2 md:px-5 px-2 bg-gray-950 md:rounded-t-3xl rounded-xl relative before:absolute before:bottom-0 before:-left-10 before:size-10 before:md:rounded-br-3xl before:rounded-br-xl before:shadow-[20px_20px_0_0_#000] after:absolute after:bottom-0 after:-right-10 after:size-10 after:md:rounded-bl-3xl after:rounded-bl-xl after:shadow-[-20px_20px_0_0_#000];
}
.button {
    @apply font-bold bg-gradient-to-b z-20 relative from-gray-800 to-gray-700 max-md:text-sm max-md:py-2 max-md:h-auto max-md:rounded-md;
}
.buttonSpan1,
.buttonSpan2 {
    @apply max-md:hidden;
}