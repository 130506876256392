.container {
    @apply w-full mx-auto xl:max-w-7xl lg:max-w-5xl md:max-w-3xl;
}

.chipBase {
    @apply py-2 mx-auto whitespace-normal h-auto w-full max-w-none animate-pulse;
}

.chipContent {
    @apply text-center text-balance md:font-semibold;
}