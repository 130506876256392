.section {
    @apply w-full mx-auto xl:max-w-7xl lg:max-w-5xl md:max-w-3xl;
}
.sectionInner {
    @apply font-bold md:text-lg text-base text-gray-50 mb-5;
}
.swiper {
    @apply w-full md:rounded-xl rounded-xl overflow-hidden;
}
.swiperSlide {
    @apply relative !h-auto;
}
.chipContainer {
    @apply absolute top-5 left-5 z-20;
}
.chipBase {
    @apply cursor-pointer;
}
.chipContent {
    @apply font-bold;
}