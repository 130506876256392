.card {
    @apply pointer-events-none;
}

.chipBase {
    @apply h-12;
}

.chipContent {
    @apply font-semibold;
}