.container {
    @apply w-full mx-auto !mt-10 !mb-5 xl:max-w-7xl lg:max-w-5xl md:max-w-3xl bg-gray-800 rounded-xl relative !px-5;
}

.image {
    @apply rounded-xl opacity-20 object-cover;
}

.swiper {
    @apply w-full !py-10 z-10 md:rounded-xl rounded-xl overflow-hidden;

}

.swiper-pagination-bullet {
    @apply bg-white;
}

.swiper-pagination {
    @apply !left-0 !transform-none;
}