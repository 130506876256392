.section {
    @apply w-full mx-auto md:mt-4 xl:max-w-7xl lg:max-w-5xl md:max-w-3xl relative;
}
.swiper {
    @apply md:rounded-xl rounded-xl overflow-hidden;
}
.swiperSlide {
    @apply md:aspect-[160/68] aspect-[160/75] relative;
}
.counterContainer {
    @apply absolute md:right-4 right-1 md:bottom-4 bottom-1 max-md:scale-75 z-10 size-10 flex items-center justify-center font-bold text-white;
}
.svg {
    @apply [--progress:0] bg-white/30 rounded-full absolute left-0 top-0 size-full [stroke-width:4px] stroke-white fill-none [stroke-dashoffset:_calc(125.6px_*_(1_-_var(--progress)))] [stroke-dasharray:125.6] [transform:rotate(-90deg)];
}