/* reelSwiperSectionSkeleton.module.scss */

.container {
    @apply w-full mx-auto xl:max-w-7xl lg:max-w-5xl md:max-w-3xl;
}

.header {
    @apply font-bold md:text-lg text-base mb-5;
}

.titleSkeleton,
.viewAllSkeleton {
    @apply inline-flex rounded-md;
}

.viewAllSkeleton {
    @apply ml-2;
}

.gridContainer {
    @apply w-full md:rounded-xl rounded-xl overflow-hidden items-stretch grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4;
}

.skeletonWrapper {
    @apply relative !h-auto;
}

.skeletonHiddenSmall {
    @apply max-md:hidden;
}

.skeletonHiddenMedium {
    @apply max-lg:hidden;
}

.skeletonHiddenLarge {
    @apply max-xl:hidden;
}

.overlay {
    @apply absolute top-5 left-5 z-20;
}

.overlayBackground {
    @apply bg-gray-950 rounded-md w-16 h-8 opacity-50;
}

.skeletonCard {
    @apply rounded-md h-full;
}

.imageSkeleton {
    @apply rounded-lg w-full aspect-[208/369];
}

.textSkeleton {
    @apply rounded-lg font-bold md:text-base text-sm mt-3;
}

.flexSkeletonContainer {
    @apply flex items-center;
}

.subTextSkeleton {
    @apply rounded-lg md:text-base text-sm my-2;
}

.subTextSkeletonMargin {
    @apply ml-2;
}